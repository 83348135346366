import React from 'react';
import styled from '@emotion/styled';
import { P, Divider, ButtonLink, BackgroundSection } from '../components/styles';
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

const Features = styled.div(({ theme }) => `
    .feature {
        margin-bottom: ${theme.spacing(4)};
    }

    @media (min-width: 1024px) {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;

        .feature {
            padding: 0 ${theme.spacing(4)};
            flex: 33.33% 0 0;
        }
    }

    .title {
        margin-top: 0;
    }

    .description {
        margin-bottom: 0;
    }

    .actions {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: ${theme.spacing(2)};
    }

    .show-more {
        display: inline-flex;
        align-items: center
    }

    .icon {
        margin-left: 5px;
    }

    .icon-arrow-down {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 3px 0 3px;
        border-color: ${theme.colors.secondary.main} transparent transparent transparent;
    }

    .icon-arrow-up {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 3px 6px 3px;
        border-color: transparent transparent ${theme.colors.secondary.main} transparent;
    }
`);

export const FeatureGrid = ({ title, features }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const initialFeatures = features.slice(0, 3);
    const moreFeatures = features.slice(3);
    const moreToShow = moreFeatures.length > 0;

    return (
        <BackgroundSection title={title} pull>
            <Features>
                    {initialFeatures.map((feature, index) => (
                        <div className="feature" key={index}>
                            <h3 className="title">{feature.title}</h3>
                            <Divider color="secondary" />
                            <P className="description">{feature.description}</P>
                        </div>
                    ))}
                    <SlideDown style={{ width: '100%' }}>
                        {isExpanded
                            ?
                                <Features>
                                    {moreFeatures.map((feature, index) => (
                                        <div className="feature" key={`more-${index}`}>
                                            <h3 className="title">{feature.title}</h3>
                                            <Divider color="secondary" />
                                            <P className="description">{feature.description}</P>
                                        </div>
                                    ))}
                                </Features>
                            : null
                        }
                    </SlideDown>
                {(moreToShow || isExpanded) &&
                    <div className="actions">
                        <ButtonLink
                            onClick={() => setIsExpanded(!isExpanded)}
                            color="secondary"
                            variant="link"
                            className="show-more"
                        >
                            Show {isExpanded ? 'Less' : 'More'}
                            <span className={`icon icon-${isExpanded ? 'arrow-up' : 'arrow-down'}`}></span>
                        </ButtonLink>
                    </div>
                }
            </Features>
        </BackgroundSection>
    );
};
