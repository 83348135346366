import React from 'react';
import styled from '@emotion/styled';
import { ButtonLink, CenteredContainer, P } from './styles';
import { urls } from '../urls';

const Container = styled.div(props => ({
    backgroundColor: props.background ? props.theme.colors.secondary.light : undefined,
    padding: props.theme.spacing(6),
    textAlign: 'center',

    '.text': {
        fontSize: 24,
    },

    '.text br': {
        display: 'none',
    },

    '@media (min-width: 400px)': {

        textAlign: 'left',

        '.text': {
            paddingRight: props.theme.spacing(4),
        },

        '.actions > *': {
            marginRight: props.theme.spacing(2),
        },

        '.text br': {
            display: 'inline',
        },

    }
}));

const Content = styled.div({
    'p': {
        margin: 0,
        marginBottom: 12,
    },

    '@media (min-width: 400px)': {
        display: 'flex',
        alignItems: 'center',

        'p': {
            textAlign: 'right',
            marginBottom: 0,
        },

        '.text': {
            width: '50%',
        },

        '.actions': {
            width: '50%',
        },

    }
});

export const CTASection = ({
    background = false
}) => {
    return null;
    // return (
    //     <Container background={background}>
    //         <CenteredContainer>
    //             <Content>
    //                 <P className="text" color="textSecondary">
    //                     <strong>
    //                         Ready to start planning<br />{' '}
    //                         your next adventure?
    //                     </strong>
    //                 </P>
    //                 <div className="actions">
    //                     <ButtonLink href={urls.signup} variant="contained" color="primary" size="large">
    //                         Try Now
    //                     </ButtonLink>
    //                 </div>
    //             </Content>
    //         </CenteredContainer>
    //     </Container>
    // );
}
