import * as React from "react"
import { Layout } from '../components/Layout';
import mapPhotoSrc from '../images/photos/jeep-desert-2.jpg';
import routeScreenshotSrc from '../images/screenshots/routes-zoomed-in.jpg';
import routeIconSrc from '../images/icons/routes.svg';
import allInOneTripPlanningPhotoSrc from '../images/photos/jeep-desert.jpg';
import allInOneTripPlanningScreenshotSrc from '../images/screenshots/all-in-one-trip-planning-v3.jpg';
import mealsScreenshotSrc from '../images/screenshots/breakfast-with-filters.jpg';
import mealsPhotoSrc from '../images/photos/meals.jpg';
import mealsIconSrc from '../images/icons/meals.svg';
import gearScreenshotSrc from '../images/screenshots/gear.jpg';
import gearPhotoSrc from '../images/photos/gear2.jpg';
import gearIconSrc from '../images/icons/gear.svg';
import mapIconSrc from '../images/icons/maps.svg';
import mapScreenshotSrc from '../images/screenshots/maps.jpg';
import routePhotoSrc from '../images/photos/maps.jpg';
import { P } from '../components/styles';
import { Section } from '../components/Section';
import { SectionAlt } from '../components/SectionAlt';
import { CTASection } from '../components/CTASection';
import { FeatureGrid } from '../components/FeatureGrid';
import SEO from "../components/seo"
import 'animate.css/animate.min.css';

import homeSlide1Src from '../images/banner/home/Slide1.jpg';
import homeSlide2Src from '../images/banner/home/Slide2.jpg';
import homeSlide3Src from '../images/banner/home/Slide3.jpg';
import homeSlide4Src from '../images/banner/home/Slide4.jpg';
import homeSlide5Src from '../images/banner/home/Slide5.jpg';

const slides = [
  // {
  //     imageSrc: homeSlide1Src,
  //     title: 'Grab Your Friends & Get Off The Grid.',
  //     description: 'Make that dream trip with your crew a reality. Build daily itineraries, routes, and meal plans, plus packing lists, in the Wanderlost Project’s all-in-one app.',
  // },
  // {
  //     imageSrc: homeSlide2Src,
  //     title: 'Adventure Like A Pro.',
  //     description: 'Your trip planning should be as dialed as your gear. We make it easy to plan your next adventure; taking the hassle out of planning while letting you build extraordinary memories.',
  // },
  // {
  //     imageSrc: homeSlide3Src,
  //     title: 'Take the Path Less Traveled.',
  //     description: 'Discover curated data sets to find new trails, points of interest and explore epic road-ready trips.',
  // },
  // {
  //     imageSrc: homeSlide4Src,
  //     title: 'Everything Tastes Better In The Wilderness.',
  //     description: 'Our Meals tool is loaded with recipes to keep you well fed on the road. Explore, create, and share recipes with your crew for snacks & cocktails to grilled feasts and bombshell breakfasts.',
  // },
  {
      imageSrc: homeSlide5Src,
      title: `We've gone on a long trip`,
      description: `But we'll be back`,
  },
];

const IndexPage = () => {
  return (
    <Layout bannerSlides={slides} noPadding>
      <SEO />

      {/* <SectionAlt
        photoSrc={allInOneTripPlanningPhotoSrc}
        screenshotSrc={allInOneTripPlanningScreenshotSrc}
      /> */}

      {/* <Section
        photoSrc={routePhotoSrc}
        screenshotSrc={routeScreenshotSrc}
        iconSrc={routeIconSrc}
        title="Reinventing the Route Planning Experience"
        flipped
        background
      >
          <P color="inherit">Finally, an innovative solution that allows you to create <strong>routes that snap to your GPX</strong> recordings, private and shared trails, and Wanderlost data. Plus <strong>find the perfect campground</strong> and add to your route.</P>
      </Section> */}
{/*
      <FeatureGrid
        title="Routing Features"
        features={[
          {
              title: 'Routing Made for Adventures',
              description: 'Route over the combo of your annotated trail layers, data shared by your friends and Wanderlost data like Forest Service Roads. See open dates, allowed vehicles where available. Invite friends to collaborate.',
          },
          {
              title: 'Elevation Profile',
              description: 'See a graph of elevation while you’re building your route, plus total distance, and total gain/loss. Get the elevation for any point on your route.',
          },
          {
              title: 'Points of Interest',
              description: 'Explore points of interest like campgrounds and hot springs, and copy to a waypoint in the route. Public Lands overlay shows where USFS, BLM, etc. boundaries are so you can tell where you can camp. ',
          },
          {
              title: 'Powerful Tools',
              description: 'Map a week’s worth of driving and split the route into day by day routes later, or merge multiple routes. Name routes, choose a color and add notes.',
          },
          {
              title: 'Routes from GPX',
              description: 'Import GPX to automatically generate a trip and route, where you can edit the route or add more routes to the trip, plus supporting info. ',
          },
          {
              title: 'Export Routes',
              description: 'Export to GPX to display on your GPS or preferred system to see the start/end, waypoints and route. ',
          },
        ]}
      /> */}

      {/* <CTASection background /> */}

      {/* <Section
        photoSrc={gearPhotoSrc}
        screenshotSrc={gearScreenshotSrc}
        iconSrc={gearIconSrc}
        title="Missing Gear Diminishes the Experience"
      >
          <P color="inherit">
            Get the gear efficiency you are always chasing. Build your packing list based on past trips; a friend’s trip; or utilize our <strong>starter lists</strong>. See what everyone else is bringing so you only <strong>pack what you need</strong>.
          </P>
      </Section> */}

      {/* <FeatureGrid
        title="Gear Features"
        features={[
          {
              title: 'Quick Start',
              description: 'Copy any combination of Wanderlost’s recommended lists to get a jumpstart on your list, then add, edit and remove items.  ',
          },
          {
              title: 'Shared Lists',
              description: 'All trip members can see everyone’s gear. Mark items as shared so your friends know what you’re bringing for everyone to use. ',
          },
          {
              title: 'Lists for Packing',
              description: 'Before You Go section of your trip shows your packing list, or print your trip and get a print friendly version.',
          },
          {
              title: 'Reusable Lists',
              description: 'Save trip packing lists as gear lists so you can easily copy in your Roof Top Tent list or your Moto Camping list on your next trip. Or simply copy from a past trip.  ',
          },
          {
              title: 'Configurable',
              description: 'Create a simple list of items, or add info like weight, quantity and notes; see total weight for your trip when you’ve added weight. ',
          },
          {
              title: 'Flexible Grouping and Sorting',
              description: 'Group by your own categories while you’re creating your list, or group by where you’ll pack while packing to make sure you’ve got everything. Sort by any column.   ',
          },
        ]}
      /> */}

      {/* <Section
        photoSrc={mealsPhotoSrc}
        screenshotSrc={mealsScreenshotSrc}
        iconSrc={mealsIconSrc}
        title="Meal Planning Made Easy"
        flipped
        background
      >
          <P color="inherit">
            Whether you are looking for quick and easy, kid friendly or gourmet we got you covered. Choose from a <strong>library of overland-ready recipes</strong> to plan delicious food for your trip.
          </P>
      </Section> */}

      {/* <FeatureGrid
        title="Meal Features"
        features={[
          {
              title: 'Recipe Library',
              description: 'Browse recipes and select one or more recipe for each day’s meals, or add your own. ',
          },
          {
              title: 'Plan by Day or by Trip',
              description: 'Use the daily plan view to see your itinerary notes and route at the same time, so you know if you need something easy. Or, use the trip view to plan all of your meals together.',
          },
          {
              title: 'Notes',
              description: 'Add notes to each meal and choose someone on the trip team to bring that meal if you’d like. ',
          },
          {
              title: 'Trip Meal Plan',
              description: 'View the plan for the whole trip, or print it when you print the trip.',
          },
          {
              title: 'Printable',
              description: 'Choose to print recipes when you print your trip to get a paper copy of your trip cookbook.',
          },
        ]}
      /> */}

      {/* <Section
        photoSrc={mapPhotoSrc}
        screenshotSrc={mapScreenshotSrc}
        iconSrc={mapIconSrc}
        title="Advanced Mapping Tools"
      >
          <P color="inherit">
            <strong>Curate your overland knowledge</strong> and experience into a secure trail library, using advanced editing tools, and <strong>share them selectively</strong>.
          </P>
      </Section> */}

      {/* <FeatureGrid
        title="Mapping Features"
        features={[
          {
              title: 'Secure Trail Library',
              description: 'Build your own layers by editing imported GPX tracks; draw, cut, glue and add details to tracks. Add annotations like trail highlights, text annotations and waypoints with custom colors and icons, notes, and more. ',
          },
          {
              title: 'Selective Sharing ',
              description: 'Organize your library and only share what you want, read-only or edit. Export any layer to GPX if you wish to use it outside of Wanderlost.  ',
          },
          {
              title: 'Favorite Layers',
              description: 'Quickly get back to the layer of tracks you’re editing, or quickly zoom to the track you’d like to route over ',
          },
        ]}
      /> */}

      {/* <CTASection background /> */}

    </Layout>
  )
}

export default IndexPage
