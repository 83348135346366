import React from 'react';
import styled from '@emotion/styled';
import { ButtonLink, CenteredContainer } from './styles';
import { urls } from '../urls';
import { ScrollAnimation } from '../components/ScrollAnimation';

const Actions = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6)
}));

const Images = styled.div`
    position: relative;
    line-height: 0;

    .photo-container {
        overflow: hidden;
    }

    .photo {
        height: 100%;
        object-fit: contain;
        margin-bottom: -1px;
    }

    .screenshot {
        position: absolute;
        top: 50%;
        right: 0;
        width: 100%;
        transform: translateY(-50%);
        text-align: center;
    }

    .screenshot img {
        box-shadow: 5px 5px 10px rgba(47, 20, 6, 0.25);
        max-width: 100%;
    }
`;

const Content = styled.div`
    text-align: center;
    color: ${props => props.theme.colors.text.textSecondary};
    padding: ${props => props.theme.spacing(4)};

    & .icon {
        margin: 0 auto 48px;
        display: block;
    }
`;

const Container = styled.div(props => ({
    ...(props.background && {
        backgroundColor: props.theme.colors.secondary.light,
    }),

    '& > *': {
        display: 'flex',
        flexDirection: 'column'
    },

    [Images]: {
        order: 1,
    },

    [Content]: {
        order: 0,
    },

    '@media (min-width: 768px)': {
        '& > *': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: props.flipped ? 'row-reverse' : 'row',
        },

        '& > * > *': {
            flexGrow: 0,
            flexShrink: 0,
        },

        [Images]: {
            order: 0,
            minWidth: 300,
            width: '40%',
        },

        [`${Images} .screenshot`]: {
            [props.flipped ? 'left' : 'right']: -90,
        },

        [Content]: {
            order: 0,
            paddingLeft: props.theme.spacing(10),
            paddingRight: props.theme.spacing(10),
            [props.flipped ? 'paddingRight' : 'paddingLeft']: `calc(90px + ${props.theme.spacing(10)})`,
            width: '60%',
        },

        [`${Content} .icon`]: {
            marginTop: -100,
        },
    }
}));

const Title = styled.h2(props => ({
    fontFamily: "'Roboto Slab', sans-serif",
    fontSize: 40,
    lineHeight: 1.3,
    margin: 0,
    marginBottom: props.theme.spacing(2),

    '@media (min-width: 1024px)': {
        fontSize: 44,
        lineHeight: 1.3,
    },
}));

const Description = styled.div`
    margin-bottom: ${props => props.theme.spacing(4)};

    p {
        font-size: 21px;
        line-height: 1.4;
        margin: 0;
    }
`;

const Divider = styled.div(props => ({
    borderTop: `7px solid ${props.theme.colors.primary.main}`,
    width: 90,
    margin: `0 auto ${props.theme.spacing(4)}`,
}));

export const Section = ({
    title,
    children: description,
    photoSrc,
    screenshotSrc,
    iconSrc,
    showSignupButton = false,
    flipped = false,
    background = false
}) => {
    return (
        <Container flipped={flipped} background={background}>
            <CenteredContainer noPadding>
                <Images>
                    <div className="photo-container">
                        <img src={photoSrc} className="photo" alt="" />
                    </div>
                    <div className="screenshot">
                        <ScrollAnimation animateIn={'fadeIn'} duration={0.5} delay={300}>
                            <img src={screenshotSrc} alt="" />
                        </ScrollAnimation>
                    </div>
                </Images>

                <Content>
                    <ScrollAnimation animateIn="fadeInUp" duration={0.5}>
                        <img src={iconSrc} className="icon" alt="" />
                        <Title>{title}</Title>
                        <Divider />
                        <Description>
                            {description}
                        </Description>
                        {showSignupButton &&
                            <Actions>
                                <ButtonLink href={urls.signup} variant="contained" color="primary">
                                    Try Now
                                </ButtonLink>
                            </Actions>
                        }
                    </ScrollAnimation>
                </Content>
            </CenteredContainer>
        </Container>
    );
};
