import React from 'react';
import styled from '@emotion/styled';
import { ButtonLink } from './styles';
import { urls } from '../urls';
import { ScrollAnimation } from '../components/ScrollAnimation';
import photoSrc from '../images/screenshots/adventure-imac.png';
import contourImageSrc from '../images/contours-bw.png';

const Container = styled.div(({ theme }) => `
    background-image: url(${contourImageSrc});
    padding: ${theme.spacing(4)};

    .title {
        font-family: 'Roboto Slab', sans-serif;
        font-weight: 700;
        font-size: 44px;
        text-align: center;
        margin: 0 0 2rem;
    }

    .text {
        list-style: none;
        color: ${theme.colors.primary.main};
        font-size: 20px;
        font-weight: bold;
        line-height: 1.5;
        margin: 0 0 1rem;
        text-align: center;
        padding: 0;
    }

    .text li {
        margin: 1rem 0;
    }

    img { max-width: 100%; }

    @media (min-width: 400px) {
        .text {
            order: 1;
        }
        .text:nth-of-type(2) {
            order: 2;
        }
        .photo {
            order: 3;
        }
    }

    @media (min-width: 768px) {
        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }

        .text {
            order: 1;
            width: 50%;
            margin: 0;
            padding: ${theme.spacing(2)};
        }

        .text:nth-of-type(2) {
            order: 2;
        }

        img {
            max-width: 600px;
            order: 3;
        }
    }

    @media (min-width: 1200px) {
        .content {
            flex-wrap: nowrap;
        }

        .text {
            order: 1;
            width: auto;
            padding-bottom: 100px;
        }

        .text:nth-of-type(1) {
            order: 1;
            text-align: right;
            padding-right: ${theme.spacing(6)};
        }

        .text:nth-of-type(2) {
            text-align: left;
            order: 3;
            padding-left: ${theme.spacing(6)};
        }

        img {
            order: 2;
            max-width: 600px;
        }
    }
`);

export const SectionAlt = () => {
    return (
        <Container>
            <h2 className="title">Plan & Share Your Adventure with a Single Tool</h2>
            <div className="content">
                <img src={photoSrc} alt="An iMac showing the home page of the Wanderlost app" />

                <ul className="text">
                    <li>Choose recipes to share</li>
                    <li>Coordinate your packing lists</li>
                    <li>Map your routes together</li>
                </ul>

                <ul className="text">
                    <li>Find free camping</li>
                    <li>Explore miles of trails</li>
                    <li>Create your own trail library</li>
                </ul>
            </div>
        </Container>
    );
};
